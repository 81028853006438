import { COLORS, SPACING } from 'helpers/globals'
import { styleFromSm, styleUpToSm, styleUpToMd } from '../../helpers/screens'

export default {
  container: {
    borderTop: `5px ${COLORS.red} solid`,
    backgroundColor: COLORS.darkGrey,
    color: COLORS.white,
    overflow: 'hidden',
    position: 'relative',
    padding: `0 ${SPACING.xxxl}`,
    ...styleUpToMd({
      paddingLeft: SPACING.base,
      paddingRight: SPACING.base,
      paddingTop: `calc(${SPACING.xxxl} - 5px)`
    })
  },
  headerSlashes: {
    bottom: 0,
    maxHeight: '70%',
    position: 'absolute',
    ...styleUpToSm({
      left: '50%',
      maxHeight: '80%'
    }),
    ...styleFromSm({ right: 0 })
  },
  content: {
    '& a': {
      color: COLORS.red
    }
  }
}
