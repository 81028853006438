import { SPACING } from 'helpers/globals'

export default {
  slash: {
    position: 'absolute',
    top: '1.75rem',
    height: SPACING.base,
    marginLeft: `-${SPACING.tiny}`
  }
}
