import { SPACING } from 'helpers/globals'
import { styleUpToLg } from 'helpers/screens'

export default {
  container: {
    padding: `0 ${SPACING.small}`,
    textAlign: 'center',
    fontWeight: 500,
    letterSpacing: '.75px',
    lineHeight: SPACING.xxxl,
    cursor: 'pointer',
    ...styleUpToLg({ padding: `0 ${SPACING.tiny}` })
  }
}
