import { COLORS, SPACING, DROPDOWN_TRANSITION } from 'helpers/globals'
import { styleUpToSm, hideAtMd, hideFromMd, styleFromXl } from 'helpers/screens'

export default {
  container: {
    ...styleFromXl({
      maxWidth: 'unset !important'
    })
  },
  nav: {
    position: 'relative'
  },
  mobileNavContainer: {
    borderTop: `5px ${COLORS.red} solid`,
    backgroundColor: COLORS.darkGrey,
    position: 'fixed',
    zIndex: 99999,
    top: 0,
    left: 0,
    width: '100vw',
    height: SPACING.xxxl,
    padding: `0 ${SPACING.large}`,
    display: 'flex',
    overflow: 'hidden',
    ...styleUpToSm({
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem'
    }),
    ...hideFromMd
  },
  navContainer: {
    height: '25%',
    ...hideAtMd
  },
  row: {
    height: '100%'
  },
  logo: {
    '& a > img': {
      height: SPACING.xl
    }
  },
  mobileLogo: {
    width: 90,
    height: 38,
    marginBottom: -5
  },
  hamburger: {
    width: 30,
    height: 24,
    overflow: 'hidden'
  },
  line: {
    width: '100%',
    height: 4,
    margin: '6px 0',
    overflow: 'hidden',
    position: 'relative',
    transition: DROPDOWN_TRANSITION,
    '&:first-of-type': { marginTop: 0 },
    '&:last-of-type': { marginBottom: 0 }
  },
  lineFill: {
    height: '100%',
    width: '100%',
    backgroundColor: COLORS.white
  },
  lineSlash: {
    backgroundColor: COLORS.white,
    transform: 'skew(-15deg)',
    height: '100%',
    width: '75%',
    position: 'absolute',
    left: '-5%',
    transition: DROPDOWN_TRANSITION
  },
  mobileNav: {
    zIndex: 999,
    position: 'fixed',
    top: SPACING.xxxl,
    right: 0,
    width: '100vw',
    backgroundColor: COLORS.red,
    transition: DROPDOWN_TRANSITION,
    ...hideFromMd
  }
}
