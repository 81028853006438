import React from 'react'
import Link from 'gatsby-link'
import injectSheet from 'react-jss'
import style from './styles'
import NavSlash from './NavSlash'
import { Location } from '@reach/router'

const NavItem = ({ location, text, classes, page }) => (
  <Link to={page}>
    <div className={classes.container}>
      <Location>
        {({ location }) => {
          let loc = location.pathname
          if (!loc.endsWith('/')) {
            loc = loc + '/'
          }
          if (loc.startsWith(page + '/')) {
            return <NavSlash />
          }
        }}
      </Location>
      <span className={classes.textSpan}>{text}</span>
    </div>
  </Link>
)

export default injectSheet(style)(NavItem)
