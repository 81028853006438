import React from 'react'
import Link from 'gatsby-link'
import injectSheet from 'react-jss'
import style from './styles'

const MobileNavItem = ({ text, classes, page }) => (
  <Link to={page}>
    <div className={classes.container}>{text}</div>
  </Link>
)

export default injectSheet(style)(MobileNavItem)
