import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import slashes from 'assets/images/slashes.svg'
import Nav from 'components/Header/Nav'

const BlogHeader = ({ title, text, classes, children }) => (
  <div className={classes.container}>
    <Nav />
    <img className={classes.headerSlashes} src={slashes} />
  </div>
)

export default injectSheet(style)(BlogHeader)
