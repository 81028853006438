import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'
import logo from 'assets/images/logo.svg'
import NavItem from './NavItem'
import MobileNavItem from './MobileNavItem'
import ImageLink from 'components/ImageLink'

class Nav extends React.Component {
  constructor() {
    super()
    this.state = {
      menuViewable: false
    }
  }

  toggleMobileMenu(viewMenu) {
    this.setState({ menuViewable: !viewMenu })
  }

  render() {
    const { classes } = this.props
    const { menuViewable } = this.state
    return (
      <div className={classes.container}>
        <div className={classes.navContainer}>
          <div className={`row no-gutters ${classes.row}`}>
            <div className={`col-sm-2 d-flex align-items-center ${classes.logo}`}>
              <ImageLink src={logo} alt='Hint Logo' href='/' />
            </div>
            <div className='col-sm-10 d-flex align-items-center justify-content-end nav'>
              {/* <NavItem text='Work' page='/work' /> */}
              <NavItem text='Services' page='/services' />
              <NavItem text='Careers' page='/careers' />
              <NavItem text='Blog' page='/blog' />
              <NavItem text='Contact' page='/contact' />
            </div>
          </div>
        </div>
        <div
          className={`${classes.mobileNavContainer} justify-content-between align-items-center`}
        >
          <ImageLink
            src={logo}
            alt='Hint Logo'
            href='/'
            imageClass={classes.mobileLogo}
          />
          <div
            className={classes.hamburger}
            onClick={this.toggleMobileMenu.bind(this, menuViewable)}
          >
            <div
              className={classes.line}
              style={{
                transformOrigin: 'right center',
                transform: menuViewable
                  ? 'translateY(0px) translateX(-4px) rotate(-45deg)'
                  : ''
              }}
            >
              <div className={classes.lineFill} />
            </div>
            <div
              className={classes.line}
              style={{ transform: menuViewable ? 'translateY(0px) rotate(45deg)' : '' }}
            >
              <div className={classes.lineFill} />
            </div>
            <div className={classes.line}>
              <div
                className={classes.lineSlash}
                style={{ left: menuViewable ? '-80%' : '' }}
              />
            </div>
          </div>
        </div>
        <div className={classes.mobileNav} style={{ top: menuViewable ? '' : '-100%' }}>
          {/* <MobileNavItem text='Work' page='/work' /> */}
          <MobileNavItem text='Services' page='/services' />
          <MobileNavItem text='Careers' page='/careers' />
          <MobileNavItem text='Blog' page='/blog' />
          <MobileNavItem text='Contact' page='/contact' />
        </div>
      </div>
    )
  }
}

export default injectSheet(style)(Nav)
