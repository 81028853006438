import React from 'react'
import { MDXRenderer } from "gatsby-plugin-mdx"
import BlogHeader from 'components/BlogHeader'
import injectSheet from 'react-jss'
import style from './styles'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import { Helmet } from 'react-helmet'

class PageTemplate extends React.Component {
  render() {
    const {
      data: {
        site: {
          siteMetadata: { siteUrl }
        },
        mdx: {
          frontmatter: {
            path,
            title,
            summary
          },
          body
        }
      },
      classes
    } = this.props

    return (
      <Layout>
        <Helmet
          meta={[
            {
              property: 'og:url',
              content: `${siteUrl}${path}`
            },
            {
              property: 'og:title',
              content: title
            },
            {
              property: 'og:description',
              content: summary
            }
          ]}
        />
        <BlogHeader />
        <div className='container'>
          <div className={classes.blogPostContainer}>
            <div className={classes.blogPost}>
              <h1>{title}</h1>

              <div className={classes.blogPostContent}>
                <MDXRenderer>{body}</MDXRenderer>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default injectSheet(style)(PageTemplate)

export const pageQuery = graphql`
  query PageByPath($path: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        path
        summary
        title
      }
    }
  }
`
